// #/utils/json/stringifyJson.ts
import superjson from 'superjson';

import { logger } from '#/logger';

export const bigintReplacer = (key, value) =>
  typeof value === 'bigint' ? value.toString() : value; // return everything else unchanged

export function stringifyJson(value: any): string {
  try {
    if (typeof value === 'string') {
      return value;
    }
    return superjson.stringify(value);
  } catch (error: any) {
    logger.error(
      `[stringifyJson > superjson.stringify] error: ${error}, value: ${value}`,
    );
    try {
      return JSON.stringify(value, bigintReplacer);
    } catch (error: any) {
      logger.error(
        `[stringifyJson > JSON.stringify] error: ${error}, value: ${value}`,
      );
      return `${value}`;
    }
  }
}
