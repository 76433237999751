// #/config/env/index.ts

import urlJoin from '#/utils/urlJoin';

function coerceUrl(str: string | null | undefined) {
  if (str == null) {
    return null;
  }
  if (str.startsWith('http')) {
    return str;
  } else {
    return urlJoin('https://', str);
  }
}

const IS_SERVER = typeof window === 'undefined';
function getBaseUrl() {
  let baseURL;
  if (IS_SERVER) {
    if (process.env.NEXT_PUBLIC_URL) {
      baseURL = process.env.NEXT_PUBLIC_URL;
    } else if (process.env.VERCEL_URL) {
      baseURL = coerceUrl(process.env.VERCEL_URL);
    } else {
      throw new Error('Missing value for NEXT_PUBLIC_URL and/or VERCEL_URL!');
    }
  } else {
    baseURL = window.location.origin;
  }
  return baseURL;
}

// export const VERCEL_URL_ROOT = getEnvSafely('NEXT_PUBLIC_VERCEL_URL');
// export const VERCEL_URL_ROOT = getEnvSafely('VERCEL_URL');
// export const URL_ROOT = getEnvSafely('NEXT_PUBLIC_URL');
export const URL_ROOT = getBaseUrl() ?? '';
export const API_ROOT = urlJoin(URL_ROOT, '/api');
export const WS_API_ROOT = API_ROOT.replace('http', 'ws');
export const CALL_ENDPOINT = `${API_ROOT}/_/function/call`;
// DEPRECATED
export const REGISTRY_ROOT = `${API_ROOT}/_registry_`;

/**
 * Third-Party Services
 */

// if (!process.env.NEXT_PUBLIC_LOG_ROCKET_PROJECT) {
//   throw new Error('Missing value for NEXT_PUBLIC_LOG_ROCKET_PROJECT!');
// }
export const LOG_ROCKET_PROJECT = process.env.NEXT_PUBLIC_LOG_ROCKET_PROJECT;

/**
 * For server use only
 */

if (IS_SERVER) {
  // Oauth1
  // if (!process.env.NEXT_TWITTER_CONSUMER_API_KEY) {
  //   throw new Error('Missing value for NEXT_TWITTER_CONSUMER_API_KEY!');
  // }

  // // Oauth1
  // if (!process.env.NEXT_TWITTER_CONSUMER_SECRET_KEY) {
  //   throw new Error('Missing value for NEXT_TWITTER_CONSUMER_SECRET_KEY!');
  // }

  // // Oauth2
  // if (!process.env.NEXT_TWITTER_CLIENT_ID) {
  //   throw new Error('Missing value for NEXT_TWITTER_CLIENT_ID!');
  // }

  // // Oauth2
  // if (!process.env.NEXT_TWITTER_CLIENT_SECRET) {
  //   throw new Error('Missing value for NEXT_TWITTER_CLIENT_SECRET!');
  // }

  // // Clearbit
  // if (!process.env.NEXT_CLEARBIT_SECRET_API_KEY) {
  //   throw new Error('Missing value for NEXT_CLEARBIT_SECRET_API_KEY!');
  // }

  // OpenAI (ChatGPT)
  // if (!process.env.NEXT_OPENAI_API_KEY) {
  //   throw new Error('Missing value for NEXT_OPENAI_API_KEY!');
  // }
}

export const TWITTER_CONSUMER_API_KEY =
  process.env.NEXT_TWITTER_CONSUMER_API_KEY;
export const TWITTER_CONSUMER_SECRET_KEY =
  process.env.NEXT_TWITTER_CONSUMER_SECRET_KEY;
export const TWITTER_BEARER_TOKEN = process.env.NEXT_TWITTER_BEARER_TOKEN;
export const TWITTER_CLIENT_ID = process.env.NEXT_TWITTER_CLIENT_ID;
export const TWITTER_CLIENT_SECRET = process.env.NEXT_TWITTER_CLIENT_SECRET;

export const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;

export const ETHERSCAN_API_KEY = process.env.NEXT_PUBLIC_ETHERSCAN_API_KEY;

// export const CLEARBIT_API_KEY = process.env.NEXT_CLEARBIT_SECRET_API_KEY;

export const OPENAI_API_KEY = process.env.NEXT_OPENAI_API_KEY;

// export const GITHUB_CLIENT_ID = getEnvSafely('GITHUB_CLIENT_ID');
// export const GITHUB_CLIENT_SECRET = getEnvSafely('GITHUB_CLIENT_SECRET');
// export const TWITCH_CLIENT_ID = getEnvSafely('TWITCH_CLIENT_ID');
// export const TWITCH_CLIENT_SECRET = getEnvSafely('TWITCH_CLIENT_SECRET');

// stripe
// export const STRIPE_API_KEY = getEnvSafely('STRIPE_API_KEY');
// export const STRIPE_WEBHOOK_SECRET = getEnvSafely('STRIPE_WEBHOOK_SECRET');

// export const CRON_API_KEY = getEnvSafely('CRON_API_KEY');

// s3 bucket
// export const S3_BUCKET_NAME = getEnvSafely('S3_BUCKET_NAME');
// export const S3_BUCKET_REGION = getEnvSafely('S3_BUCKET_REGION');
// export const S3_BUCKET_ACCESS_KEY = getEnvSafely('S3_BUCKET_ACCESS_KEY');
// export const S3_BUCKET_SECRET_ACCESS_KEY = getEnvSafely('S3_BUCKET_SECRET_ACCESS_KEY');

// storj bucket
// export const STORJ_ACCESS_KEY = getEnvSafely('NEXT_STORJ_ACCESS_KEY');
// export const STORJ_SECRET_KEY = getEnvSafely('NEXT_STORJ_SECRET_KEY');
// export const STORJ_BUCKET = getEnvSafely('NEXT_STORJ_BUCKET');
// export const STORJ_ENDPOINT = getEnvSafely('NEXT_STORJ_ENDPOINT');

export const CRON_KEY = process.env.CRON_KEY;

export const ZEPLO_TOKEN = process.env.ZEPLO_TOKEN;

/**
 * Default Export
 */

const env = {
  URL_ROOT,
  API_ROOT,
  CALL_ENDPOINT,
  // GITHUB_CLIENT_ID,
  // GITHUB_CLIENT_SECRET,
  // TWITCH_CLIENT_ID,
  // TWITCH_CLIENT_SECRET,
  // STRIPE_API_KEY,
  // STRIPE_WEBHOOK_SECRET,
  // CRON_API_KEY,
  // S3_BUCKET_NAME,
  // S3_BUCKET_REGION,
  // S3_BUCKET_ACCESS_KEY,
  // S3_BUCKET_SECRET_ACCESS_KEY,
  CRON_KEY,
  ZEPLO_TOKEN,
  LOG_ROCKET_PROJECT,
  TWITTER_CONSUMER_API_KEY,
  TWITTER_CONSUMER_SECRET_KEY,
  TWITTER_BEARER_TOKEN,
  TWITTER_CLIENT_ID,
  TWITTER_CLIENT_SECRET,
  // CLEARBIT_API_KEY,
  OPENAI_API_KEY,
};

export default env;
