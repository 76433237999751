// #/services/workflows/createNode.ts

import { Node } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { merge } from 'lodash';

import { ActionNodeData, NodeType, Prerequisite } from '#/types/workspace';

export function titleSublineDetailsFromActionNodeData(
  nodeData: Partial<
    { title: string; serviceKey: string; actionKey: string } & ActionNodeData
  >,
) {
  const _title = nodeData.title;

  let title = nodeData.serviceKey,
    subline = nodeData.actionKey,
    detail;
  if (_title != null && _title !== '' && _title !== title) {
    title = _title;
    subline = nodeData.serviceKey;
    detail = nodeData.actionKey;
  }

  return { title, subline, detail };
}

export async function createActionNode(args: {
  id: string;
  position: { x: number; y: number };
  type?: NodeType;
  data: {
    uuid?: string;
    isLateral?: boolean;
    title?: string;
    logoUrl?: string;
    serviceKey?: string;
    actionKey?: string;
    actionVerb?: string;
    actionEndpoint?: string;
    context?: Record<string, any>;
    prerequisites?: Prerequisite[];
    inputSchema?: Record<string, any>;
    inputValues?: any;
    outputSchema?: Record<string, any>;
  };
}): Promise<Node<ActionNodeData>> {
  const {
    id,
    position,
    type: nodeType = 'turbo',
    data: {
      uuid = uuidv4(),
      isLateral = false,
      title: _title,
      logoUrl,
      serviceKey,
      actionKey,
      // actionVerb,
      // actionEndpoint,
      context = {},
      prerequisites = [],
      inputSchema,
      inputValues,
      outputSchema,
      // ...restData
    },
    // ...rest
  } = args;

  let actionDetails;
  if (serviceKey != null && actionKey != null) {
    try {
      const response = await fetch(
        `/api/registry/getActionDetails?${new URLSearchParams({
          serviceKey,
          actionKey,
        })}`,
      );
      actionDetails = await response.json();
    } catch (error: any) {
      console.error(error);
    }
    console.debug('actionDetails:', actionDetails);
  }

  const {
    // serviceKey,
    // serviceUrlRoot,
    serviceLogoUrl,
    // actionKey,
    actionVerb,
    // actionPath,
    actionEndpoint,
    defaultContext = {},
    defaultPrerequisites = [],
    inputSchema: _inputSchema,
    defaultInputValues,
    outputSchema: _outputSchema,
  } = actionDetails ?? {};

  const { title, subline, detail } = titleSublineDetailsFromActionNodeData(
    args.data as ActionNodeData,
  );

  return {
    // ...rest,
    id,
    position,
    type: nodeType,
    data: {
      // ...restData,
      uuid,
      isLateral,
      title,
      subline,
      detail,
      icons: ['FunctionIcon'],
      logoUrl: serviceLogoUrl ?? logoUrl,
      type: 'action',
      serviceKey,
      actionKey,
      actionVerb: actionKey == null ? null : actionVerb,
      actionEndpoint: actionKey == null ? null : actionEndpoint,
      context: merge({}, defaultContext ?? {}, context ?? {}),
      prerequisites: [
        ...(defaultPrerequisites ?? []),
        ...(prerequisites ?? []),
      ],
      inputSchema:
        actionKey == null ? null : inputSchema ?? _inputSchema ?? null,
      inputValues:
        actionKey == null
          ? null
          : merge({}, defaultInputValues ?? {}, inputValues ?? {}),
      outputSchema:
        actionKey == null ? null : outputSchema ?? _outputSchema ?? null,
    },
  };
}

export default createActionNode;
