'use client';

import React, { createContext, useContext, useState } from 'react';

import { useAsyncMemo } from '#/hooks/useAsyncMemo';
import { User } from '#/services/user';
import { IProfile } from '#/types/profile';
import { UserSession } from '#/types/user';
import { identifyUser as logrocketIdentifyUser } from '#/services/logrocket';

type SetterFn<T> = (arg1: T) => void;
type Setter<T> = ((fn: SetterFn<T>) => void) | SetterFn<T>;
interface UserContextValues {
  userSession: UserSession;
  setUserSession: Setter<UserSession>;
  user: User | undefined;
  userProfile: IProfile | undefined;
  updateProfile: SetterFn<IProfile>;
  connectedProviders: string[];
}

// const profileDefaultValue = {
//   details: {
//     publicAddress: '0x0',
//     bio: 'Software engineer looking to use Simpfi',
//     avatarUrl: 'https://simpfi.ai/assets/img/logos/imagelogo.png',
//     location: 'Raleigh , NC',
//     websiteUrl: 'http://keepinitcoolwithsimpfi.com',
//     firstName: 'John',
//     lastName: 'Doe',
//   },
//   socials: {
//     twitter: 'https://x.com/SimpFiAi',
//     twitterUsername: '@SimpFiAi',
//   },
// };

export const UserContext = createContext<UserContextValues>({
  userSession: undefined,
  setUserSession: () => {},
  user: undefined,
  userProfile: undefined, // profileDefaultValue,
  updateProfile: () => {},
  connectedProviders: [],
});

export const useUserContext = () => useContext(UserContext);

interface UserContextProviderProps {
  children: React.ReactNode;
}

const UserContextProvider = (props: UserContextProviderProps) => {
  const redirectTo = '/dashboard';
  const redirectIfFound = true;

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userSession, setUserSession] = useState<UserSession>();
  // const [user, setUser] = useState<User>();

  const user = useAsyncMemo(async () => {
    if (userSession?.address == null) {
      return undefined;
    }
    const newUser = new User({ address: userSession?.address, chainId: 1 });
    // Make sure the user folder exists
    const result = await newUser.createOrRetrieveUserFolder();
    // console.debug('[getUser] result:', result);

    logrocketIdentifyUser({ user: newUser, userSession });

    return newUser;
  }, [userSession?.address]);

  // TODO: reloadUserSession
  // ...

  const userProfile = useAsyncMemo(async () => {
    if (user == null) {
      return undefined;
    }

    const newUserProfile = (await user.getProfile()) ?? {};

    // This section should be deleted when not trying to display dummy values
    // newUserProfile.details = profileDefaultValue?.details;
    //

    console.debug('[getUserProfile] newUserProfile:', newUserProfile);

    return newUserProfile;
  }, [user]);

  const updateProfile = async (updatedProfile: IProfile) => {
    if (user == null) {
      console.warn('Cannot update user profile when no user is loaded.');
      return undefined;
    }
    await user.updateProfile(updatedProfile);
    // TODO: reload user?
  };

  // TODO: bugging out with can only use Router on client error but this is a
  // client only component...
  //
  // useEffect(() => {
  //   // if no redirect needed, just return (example: already on /dashboard)
  //   // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
  //   if (!redirectTo || !user) return;

  //   if (
  //     // If redirectTo is set, redirect if the user was not found.
  //     (redirectTo && !redirectIfFound && !user) ||
  //     // If redirectIfFound is also set, redirect if the user was found
  //     (redirectIfFound && user)
  //   ) {
  //     Router.push(redirectTo);
  //   }
  // }, [user, redirectIfFound, redirectTo]);

  const value = {
    userSession,
    setUserSession,
    user,
    userProfile,
    updateProfile,
    connectedProviders: userSession?.connectedProviders ?? [],
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export default UserContextProvider;
