// #/types/workspace.ts
import { Node, Edge } from 'reactflow';
import find from 'lodash/find';
import { v4 as uuidv4 } from 'uuid';
import { cronScheduleFromWorkflow } from '../services/workflows/cronScheduleFromWorkflow';

export type Prerequisite = 'OAUTH_1_0A' | 'OAUTH_2_0';

export interface WorkflowCronTab {
  [cronSchedule: string]: { [workflowSnapshotUuid: string]: boolean };
}

export interface IWorkspace {
  id?: string; //postgres id

  uuid: string; //uuid
  context?: Record<string, any>;
  templateContext?: Record<string, any>;
  prerequisites?: Prerequisite[];

  originalWorkflowUuid?: string; //uuid
  templateId?: string; // uuid
  templateOwner?: string; //wallet

  organizationUuid?: string; //uuid

  coverImageUrl?: string;
  coverRevealImageUrl?: string;
  promoVideoUrl?: string;

  instructions?: { id: string; text: string }[];
  videoTutorialUrl?: string; //url
  requiredThirdPartyServices?: { name: string; url: string }[];

  price?: { amount: number; currency: string };

  salesPitchHtml?: string;
  author?: { name: string; username: string; avatarUrl: string };
  requirements?: string[];
  specifications?: string[];
  versionNotes?: string;
  reviews?: { author: string; date: string; rating: number; text: string }[];

  addons?: {
    [addon: string]: {
      title: string;
      enabled: boolean;
      context: Record<string, any>;
      price: { amount: number; currency: string }
    }
  };

  input?: any; //inputs to the workspace
  nodes: INode[] | [];
  edges: Edge[] | [];

  hash?: string; //hash
  contract?: string; //contract address
  owner?: string; //wallet
  name?: string; //title
  label?: string; //subtitle
  description?: string; //markdown
  // coverImageKey?: string;
  members?: string[]; //wallets
  icons?: string[];
  type?: any | null;
  data?: any | null;
  category?: any | null;
  tags?: any | null; //#hashtags
  origin?: string; //the id of the hash that this workspace was forked from
  origins?: string[]; //the ids of the hashes that this workspace was forked from
  dna?: string; //the id of the hash that this workspace sythesized from
  dnas?: string[]; //the ids of the hashes that this workspace sythesized from
  archived?: boolean;
  public?: boolean;
  shared?: boolean; //merkle root
  sharedWith?: string[]; //wallets
  sharedWithGroups?: string[]; //group ids
  sharedWithOrganizations?: string[]; //organization ids
  secret?: string; //Lit signed secret/public key pair
  secretKey?: string; //Lit signed secret/public key pair

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

export interface IWorkflow {
  id?: string; //postgres id
  uuid: string; //uuid
  hash?: string; //hash
  version?: number | string
  owner?: string; //wallet

  originalWorkflowUuid?: string; //uuid
  templateId?: string; // uuid
  templateOwner?: string; //wallet

  organizationUuid?: string; //uuid

  name?: string; //title
  label?: string; //subtitle
  description?: string; //markdown
  icons?: string[];
  coverImageUrl?: string;
  coverRevealImageUrl?: string;
  promoVideoUrl?: string;

  context?: Record<string, any>;
  templateContext?: Record<string, any>;

  instructions?: { id: string; text: string }[];
  videoTutorialUrl?: string; //url
  requiredThirdPartyServices?: { name: string; url: string }[];

  price?: { amount: number; currency: string };

  salesPitchHtml?: string;
  author?: { name: string; username: string; avatarUrl: string };
  requirements?: string[];
  specifications?: string[];
  versionNotes?: string;
  reviews?: { author: string; date: string; rating: number; text: string }[];

  addons?: {
    [addon: string]: {
      title: string;
      enabled: boolean;
      context: Record<string, any>;
      price: { amount: number; currency: string }
    }
  };

  cronSchedule?: string;
  deploymentType?: DeploymentType;
  enabledStatus?: EnabledStatus;

  prerequisites?: Prerequisite[];

  nodes: INode[] | [] | undefined;
  edges: Edge[] | [];

  input?: any; //inputs to the workspace
  output?: any; //outputs from the workspace

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

export function workflowFromWorkspace(workspace?: IWorkspace): IWorkflow {
  return {
    uuid: workspace?.uuid,
    hash: workspace?.hash,
    version: workspace?.version,
    owner: workspace?.owner,

    // originalWorkflowUuid?: string; //uuid
    // templateId?: string; // uuid
    // templateOwner?: string; //wallet
    originalWorkflowUuid: workspace?.originalWorkflowUuid,
    templateId: workspace?.templateId,
    templateOwner: workspace?.templateOwner,

    organizationUuid: workspace?.organizationUuid,

    name: workspace?.name,
    label: workspace?.label,
    description: workspace?.description,
    icons: workspace?.icons,
    coverImageUrl: workspace?.coverImageUrl,
    coverRevealImageUrl: workspace?.coverRevealImageUrl,
    promoVideoUrl: workspace?.promoVideoUrl,

    context: workspace?.context,
    templateContext: workspace?.templateContext,

    instructions: workspace?.instructions,
    videoTutorialUrl: workspace?.videoTutorialUrl,
    requiredThirdPartyServices: workspace?.requiredThirdPartyServices,

    price: workspace?.price,

    salesPitchHtml: workspace?.salesPitchHtml,
    author: workspace?.author,
    requirements: workspace?.requirements,
    specifications: workspace?.specifications,
    versionNotes: workspace?.versionNotes,
    reviews: workspace?.reviews,

    addons: workspace?.addons,

    input: workspace?.input,
    nodes: workspace?.nodes,
    edges: workspace?.edges,

    cronSchedule: cronScheduleFromWorkflow(workspace as IWorkflow),

    createdAt: workspace?.createdAt,
    updatedAt: workspace?.updatedAt,
    deletedAt: workspace?.deletedAt,
  } as IWorkflow;
}

type DeploymentType = 'TEST' | 'DEPLOYED';
type EnabledStatus = 'ENABLED' | 'DISABLED';

export interface IWorkflowSnapshot {
  id?: string; //postgres id
  uuid: string; //uuid
  hash?: string; //hash
  workflowUuid: string;
  version?: string;
  timestamp: number;
  uri?: string;
  json: IWorkflow | Record<string, any>;
  owner?: string; //wallet
  cronSchedule?: string;
  deploymentType?: DeploymentType;
  enabledStatus?: EnabledStatus;

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

const findNode = (workflowSnapshotJson: IWorkspace, nodeQuery: any) => {
  return find(workflowSnapshotJson.nodes, nodeQuery);
};

export function createWorkflowSnapshot(args: {
  iWorkflow: IWorkflow;
  iWorkflowSnapshot?: Partial<IWorkflowSnapshot>;
}): IWorkflowSnapshot {
  const date = new Date();
  const timestampMs = date.getTime();
  const timestampIso = date.toISOString();
  const { iWorkflow, iWorkflowSnapshot } = args;
  return {
    // DEFAULT VALUES
    uuid: uuidv4(),
    hash: iWorkflow?.hash,
    workflowUuid: iWorkflow?.uuid,
    timestamp: timestampMs,
    json: iWorkflow,
    owner: iWorkflow?.owner,
    cronSchedule: cronScheduleFromWorkflow(iWorkflow),
    deploymentType: 'TEST',
    enabledStatus: 'ENABLED',
    createdAt: timestampIso,
    updatedAt: timestampIso,

    // OVERWRITE DEFAULTS WITH PROVIDED VALUES
    ...iWorkflowSnapshot,
  };
}

export type NodeType = 'turbo';

// export type NodeDataType = 'trigger' | 'action';

export interface NodeData {
  uuid: string;
  // type: NodeDataType;
  title: string;
  subline: string;
  detail?: string;
  icons: string[];
  isLateral: boolean;
  logoUrl?: string;
}

export type TriggerType = 'cron' | 'switch' | 'webhook';

export interface TriggerData {
  // Task Specification
  triggerType: TriggerType;

  // Context
  // context?: Record<string, any>;

  // Input
  inputSchema?: Record<string, any>;
  inputFormJsonSchema?: Record<string, any>;
  inputValues: Record<string, any>;

  // Output
  outputSchema?: Record<string, any>;
  output?: Record<string, any>;
}

export interface TriggerNodeData extends NodeData, TriggerData {
  // NodeData
  // ...
  type: 'trigger';

  // TriggerData
  // ...
}

export type ActionType = 'action' | 'web3-action' | 'function-action';
// export type ActionType = 'http' | 'evm' | 'expression';

export interface HttpTaskSpecification {
  serviceKey: string;
  serviceRootUrl?: string;

  actionKey: string;
  actionVerb: string;
  actionEndpoint: string;

  oasRequestSchema?: Record<string, any>;
}

export interface HttpInput {
  headers: Record<string, any>;
  path: Record<string, any>;
  query: Record<string, any>;
  bodyJson: Record<string, any>;
}

export interface HttpActionData extends HttpTaskSpecification {
  // Task Specification
  // ...

  // Context
  context?: Record<string, any>;

  // Prerequisites
  prerequisites?: Prerequisite[];

  // Input
  inputSchema?: HttpInput;
  // inputFormJsonSchema?: HttpInput;
  inputValues?: HttpInput;
  input?: HttpInput;

  // Output
  outputSchema?: Record<string, any>;
  output?: Record<string, any>;
}

export interface HttpActionNodeData extends NodeData, HttpActionData {
  // NodeData
  // ...
  type: 'action';
  actionType: 'http';

  // HttpActionData
  // ...
}

export interface EvmTaskSpecification {
  networkName: string;
  contractAddress: string;
  functionName: string;
  abi?: Record<string, any>; // ABI for the function arguments
}

export interface EvmInput {
  execute?: boolean;
  args: Record<string, any>;
  tx: Record<string, any>;
}

export interface EvmActionData extends EvmTaskSpecification {
  // Task Specification
  // ...

  // Context
  // context?: Record<string, any>;

  // Prerequisites
  // ...

  // Input
  inputSchema?: EvmInput;
  // inputFormJsonSchema?: EvmInput;
  inputValues: EvmInput;
  input?: EvmInput;

  // Output
  outputSchema?: Record<string, any>;
  output?: Record<string, any>;
}

export interface EvmActionNodeData extends NodeData, EvmActionData {
  // NodeData
  // ...
  type: 'web3-action';
  // type: 'action';
  actionType: 'evm';

  // EvmActionData
  // ...
}

export interface ExpressionTaskSpecification {
  expression: string;
}

export interface ExpressionActionData extends ExpressionTaskSpecification {
  // Task Specification
  // ...

  // Context
  // context?: Record<string, any>;

  // Prerequisites
  // ...

  // Input
  inputSchema?: Record<string, any>;
  // inputFormJsonSchema?: Record<string, any>;
  inputValues: Record<string, any>;

  // Output
  outputSchema?: Record<string, any>;
  output?: Record<string, any>;
}

export interface ExpressionActionNodeData extends NodeData, ExpressionActionData {
  // NodeData
  // ...
  type: 'function-action';
  // type: 'action';
  actionType: 'expression';

  // ExpressionActionData
  // ...
}

export type TriggerNode = Node<TriggerNodeData>;
export type HttpActionNode = Node<HttpActionNodeData>;
export type EvmActionNode = Node<EvmActionNodeData>;
export type ExpressionActionNode = Node<ExpressionActionNodeData>;

export type NodeDataType = 'trigger' | 'action' | 'web3-action' | 'function-action';

export type INodeData = TriggerNodeData | HttpActionNodeData | EvmActionNodeData | ExpressionActionNodeData;
export type INode = Node<INodeData>;

export type RunStatus =
  | 'PENDING'
  | 'RUNNING'
  | 'SUCCESS'
  | 'ERROR'
  | 'FAILED'
  | 'CANCELED';

// TODO: xState
// export type IWorkflowRunSM;

export interface IWorkflowRun {
  // Workflow & Snapshot
  workflowUuid?: string; //uuid
  workflowVersion?: string | number;
  workflowSnapshotJson?: Record<string, any>;

  // Run ID
  // uuid?: string; //uuid
  runId?: string;

  // Run Specification
  deploymentType: DeploymentType;

  // json: IWorkflowRunSM;

  status: RunStatus;

  input?: Record<string, any>; //inputs to the workflow run
  output?: Record<string, any>;

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

export interface IWorkflowTaskRun {
  // Workflow & Snapshot
  workflowUuid?: string; //uuid
  workflowVersion?: string; //uuid
  workflowSnapshotJson?: Record<string, any>;

  // Run ID
  workflowRunId: string;

  // Task Run ID
  nodeId: string; //workflow node id
  nodeUuid: string; //workflow node uuid

  // Node Data
  nodeData: INodeData;

  // Task Run Specification
  status: RunStatus;
  input: Record<string, any>;
  output?: Record<string, any>;
  error?: Error;

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

export type Task = {
  id: string; //uuid
  hash: string; //ipfs hash
  contract: string; //contract address
  owner: string; //wallet
  name: string; //title
  label: string; //subtitle
  description: string; //markdown
};

export type Service = {
  id: string; //uuid
  hash: string; //ipfs hash
  contract: string; //contract address
  owner: string; //wallet
  name: string; //title
  label: string; //subtitle
  description: string; //markdown
  tags: string[];
  category: string[];
  type: string;
  data: string;
  tasks: Array<Task>;
};

export type Organization = {
  id: string; //uuid
  hash: string; //ipfs hash
  contract: string; //contract address
  owner: string;
  name: string; //title
  label: string; //subtitle
  description: string; //markdown
  members: string[]; //wallets
  icons: string[];
  nodes: Node[] | [];
  edges: Edge[] | [];
  type: any | null;
  data: any | null;
  category: any | null;
  tags: any | null; //#hashtags
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};
