// #/services/workspaces/updateWorkspace.ts
import { IWorkspace } from '#/types/workspace';

import getWorkspace from './getWorkspace';
import writeWorkspace from './writeWorkspace';

export default async function updateWorkspace(args: {
  ownerAddress: string;
  workspaceUuid: string;
  updatedWorkspace: Partial<IWorkspace>;
}): Promise<IWorkspace> {
  try {
    const { ownerAddress, workspaceUuid, updatedWorkspace } = args;

    const currentWorkspace = await getWorkspace({
      ownerAddress,
      workspaceUuid,
    });

    const mergedWorkspace = {
      uuid: workspaceUuid,
      ...currentWorkspace,
      ...updatedWorkspace,
    };

    const newWorkspace = await writeWorkspace({
      ownerAddress,
      workspaceUuid,
      workspace: mergedWorkspace,
    });

    return newWorkspace;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error updating workspace: ${error.message}`);
  }
}
